<template>
  <div class="wrap">
    <div class="titleBox">
      <img
        src="../../assets/kingOfEating/image/title.png"
        alt=""
        class="title"
      />
      <img
        src="../../assets/kingOfEating/image/return.png"
        alt=""
        class="closeGame"
        @click="close"
      />
    </div>

    <div class="announcementBox">
      <div class="announcement-prize">
        <van-notice-bar :scrollable="false">
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :touchable="false"
            :show-indicators="false"
          >
            <van-swipe-item v-for="item in gamerecordList" :key="item">
              {{ item }}</van-swipe-item
            >
          </van-swipe>
        </van-notice-bar>
      </div>
      <div class="announcement-notice">
        <div v-if="!showEvents">
          游戏即将开始
        </div>
      </div>
    </div>

    <div class="featureBox">
      <div>
        <img
          src="../../assets/kingOfEating/image/mall.png"
          alt=""
          @click="propertyGiftHandle"
        />
      </div>
      <div @click="showRule = !showRule">
        <img src="../../assets/kingOfEating/image/rule.png" alt="" />
      </div>
      <div>
        <img
          src="../../assets/kingOfEating/image/eventGift.png"
          alt=""
          @click="eventGiftHandle"
        />
      </div>
      <div>
        <img
          src="../../assets/kingOfEating/image/records.png"
          alt=""
          @click="getHistoryHandle"
        />
      </div>
    </div>

    <!-- 道具和礼物 -->
    <div class="property-gift" v-if="showPropertyGift">
      <div class="property-gift-box">
        <img
          src="../../assets/kingOfEating/image/return.png"
          alt=""
          class="property-gift-close"
          @click="showPropertyGift = false"
        />
        <div class="type-btn">
          <div
            :class="{ typeActive: typeActive == 0 }"
            @click="typeActivehandle(0)"
          >
            道具商城
          </div>
          <div
            :class="{ typeActive: typeActive == 1 }"
            @click="typeActivehandle(1)"
          >
            活动礼物
          </div>
        </div>
        <div class="content">
          <div
            class="content-item"
            v-for="(item, index) in MallArray"
            :key="index"
          >
            <div
              class="content-item-top"
              :class="{ contentTopActive: typeItem == index }"
              @click="typeItem = index"
            >
              <div class="top-fish">
                {{
                  typeActive == 0
                    ? `鱼干${item.get_fish_num}`
                    : item.get_fish_num
                }}
              </div>
              <img :src="item.image" alt="" />
              <div class="top-price">
                <img
                  src="../../assets/kingOfEating/image/driedFish.png"
                  alt=""
                  style="height: .26rem; vertical-align: middle;"
                  v-if="typeActive == 1"
                />
                <img
                  src="../../assets/gold.png"
                  alt=""
                  style="height: .26rem; vertical-align: middle;"
                  v-if="typeActive == 0"
                />
                {{ item.jin_bi_num }}
              </div>
            </div>
            <div
              class="content-item-btn"
              :class="{ contentActive: typeItem == index }"
              @click="exchangeGiftHandle(item)"
            >
              兑换
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 获奖弹框 -->
    <div class="givePrize" v-if="showGivePrize">
      <div class="givePrize-box" v-if="isZhong">
        <img
          src="../../assets/kingOfEating/image/congratulations.png"
          alt=""
          class="congratulationsIcon"
        />
        <div class="givePrize-info">
          <img :src="gameWinningImg" alt="" class="givePrize-info-avatar" />
          <div class="givePrize-info-right">
            <div class="givePrize-info-name">{{ gameWinningRod }} 获胜</div>
            <div class="givePrize-info-numTip">本轮倍率</div>
          </div>
        </div>
        <div class="multipleRoll">
          <div class="bigOne">
            {{ (gameSpeed*1)+1 }} 倍
          </div>
           <img src="../../assets/kingOfEating/image/driedFish.png" alt=""> {{ gameSpeed }} 倍
          </div>
        <div class="prizeNum">{{ gameFishNum }}小鱼干</div>
      </div>

      <div class="givePrize-box-zhong" v-else>
        <img
          src="../../assets/kingOfEating/image/henyihan.png"
          alt=""
          class="congratulationsIcon"
        />
        <div>
          <img src="../../assets/kingOfEating/image/ku.png" alt="" />
          很遗憾未中奖，请再接再厉！
        </div>
      </div>
    </div>

    <!-- 活动 -->
    <div class="eventsBox" v-if="showEvents">
      <div class="events-time">
        <van-count-down :time="countdown" format="ss" />
      </div>
      <div class="events-item-box">
        <div class="events-item"  @click="checkHandle(0, 'ke_ai_meng_tu')">
          <div
            class="events-item-top"
            :class="{ eventsTopActive: isBetting == 0 }"
           
          >
            <div class="name">可爱萌兔</div>
            <img src="../../assets/kingOfEating/image/tuzi.png" alt="" />
            <div class="price">
              <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
              {{ ke_ai_meng_tu_all_num }}
            </div>
          </div>
          <div
            class="events-item-btn"
            :class="{ eventsActive: isBetting == 0 }"
          >
            <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
            {{ ke_ai_meng_tu_num }}
          </div>
        </div>
        <div class="events-item"  @click="checkHandle(1, 'cong_ming_hu_li')">
          <div
            class="events-item-top"
            :class="{ eventsTopActive: isBetting == 1 }"
           
          >
            <div class="name">聪明狐狸</div>
            <img src="../../assets/kingOfEating/image/huli.png" alt="" />
            <div class="price">
              <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
              {{ cong_ming_hu_li_all_num }}
            </div>
          </div>
          <div
            class="events-item-btn"
            :class="{ eventsActive: isBetting == 1 }"
          >
            <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
            {{ cong_ming_hu_li_num }}
          </div>
        </div>
        <div class="events-item"  @click="checkHandle(2, 'qin_lao_xiao_niao')">
          <div
            class="events-item-top"
            :class="{ eventsTopActive: isBetting == 2 }"
           
          >
            <div class="name">勤劳小鸟</div>
            <img src="../../assets/kingOfEating/image/niao.png" alt="" />
            <div class="price">
              <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
              {{ qin_lao_xiao_niao_all_num }}
            </div>
          </div>
          <div
            class="events-item-btn"
            :class="{ eventsActive: isBetting == 2 }"
          >
            <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
            {{ qin_lao_xiao_niao_num }}
          </div>
        </div>
        <div class="events-item"  @click="checkHandle(3,'tan_chi_he_ma')">
          <div
            class="events-item-top"
            :class="{ eventsTopActive: isBetting == 3 }"
           
          >
            <div class="name">贪吃河马</div>
            <img src="../../assets/kingOfEating/image/hema.png" alt="" />
            <div class="price">
              <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
              {{ tan_chi_he_ma_all_num }}
            </div>
          </div>
          <div
            class="events-item-btn"
            :class="{ eventsActive: isBetting == 3 }"
          >
            <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
            {{ tan_chi_he_ma_num }}
          </div>
        </div>
        <div class="events-item"  @click="checkHandle(4,'yong_lan_xiao_mao')">
          <div
            class="events-item-top"
            :class="{ eventsTopActive: isBetting == 4 }"
          
          >
            <div class="name">慵懒小猫</div>
            <img src="../../assets/kingOfEating/image/xiaomao.png" alt="" />
            <div class="price">
              <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
              {{ yong_lan_xiao_mao_all_num }}
            </div>
          </div>
          <div
            class="events-item-btn"
            :class="{ eventsActive: isBetting == 4 }"
          >
            <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
            {{ yong_lan_xiao_mao_num }}
          </div>
        </div>
        <div class="events-item" @click="checkHandle( 5,'ben_ben_xiao_xiong')">
          <div
            class="events-item-top"
            :class="{ eventsTopActive: isBetting == 5 }"
            
          >
            <div class="name">笨笨小熊</div>
            <img src="../../assets/kingOfEating/image/xiaoxiong.png" alt="" />
            <div class="price">
              <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
              {{ ben_ben_xiao_xiong_all_num }}
            </div>
          </div>
          <div
            class="events-item-btn"
            :class="{ eventsActive: isBetting == 5 }"
          >
            <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
            {{ ben_ben_xiao_xiong_num }}
          </div>
        </div>
      </div>
      <div class="events-betting">
        <div class="events-betting-num">
          <span> {{ fishNum }}</span>
          <button class="addBtn" @click="addFishNumHandle"></button>
        </div>
        <div
          class="events-betting-btn events-betting-one"
          @click="bettingBtn(100)"
        >
          1百
        </div>
        <div
          class="events-betting-btn events-betting-two"
          @click="bettingBtn(1000)"
        >
          1千
        </div>
        <div
          class="events-betting-btn events-betting-three"
          @click="bettingBtn(5000)"
        >
          5千
        </div>
        <div
          class="events-betting-btn events-betting-four"
          @click="bettingBtn(10000)"
        >
          1万
        </div>
      </div>
    </div>

    <!-- 开奖 -->
    <div class="drawLottery" v-if="showDrawLottery">
      <div class="drawLottery-box">
        <div class="drawLottery-item">
          <div
            class="drawLottery-item-top"
            :class="{ drawLotteryActive: drawLotteryActiveNum == ke_ai_meng_tu_eat_num }"
          >
            <div class="name">可爱萌兔</div>
            <img src="../../assets/kingOfEating/image/tuzi.png" alt="" />
            <div class="price">
              <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
              <span class="spanNum">{{ ke_ai_meng_tu_eat_num }}</span>
            </div>
          </div>
        </div>
        <div class="drawLottery-item">
          <div
            class="drawLottery-item-top"
              :class="{ drawLotteryActive: drawLotteryActiveNum == cong_ming_hu_li_eat_num }"
          >
            <div class="name">聪明狐狸</div>
            <img src="../../assets/kingOfEating/image/huli.png" alt="" />
            <div class="price">
              <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
              <span class="spanNum">{{ cong_ming_hu_li_eat_num }}</span>
            </div>
          </div>
        </div>
        <div class="drawLottery-item">
          <div
            class="drawLottery-item-top"
           :class="{ drawLotteryActive: drawLotteryActiveNum == qin_lao_xiao_niao_eat_num}"
          
          >
            <div class="name">勤劳小鸟</div>
            <img src="../../assets/kingOfEating/image/niao.png" alt="" />
            <div class="price">
              <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
              <span class="spanNum">{{ qin_lao_xiao_niao_eat_num }}</span> 
            </div>
          </div>
        </div>
        <div class="drawLottery-item">
          <div
            class="drawLottery-item-top"
              :class="{ drawLotteryActive: drawLotteryActiveNum == tan_chi_he_ma_eat_num}"
          >
            <div class="name">贪吃河马</div>
            <img src="../../assets/kingOfEating/image/hema.png" alt="" />
            <div class="price">
              <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
              <span class="spanNum">{{ tan_chi_he_ma_eat_num }}</span>
            </div>
          </div>
        </div>
        <div class="drawLottery-item">
          <div
            class="drawLottery-item-top"
            :class="{ drawLotteryActive: drawLotteryActiveNum == yong_lan_xiao_mao_eat_num}"
          >
            <div class="name">慵懒小猫</div>
            <img src="../../assets/kingOfEating/image/xiaomao.png" alt="" />
            <div class="price">
              <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
              <span class="spanNum">{{ yong_lan_xiao_mao_eat_num }}</span>
            </div>
          </div>
        </div>
        <div class="drawLottery-item">
          <div
            class="drawLottery-item-top"
              :class="{ drawLotteryActive: drawLotteryActiveNum == ben_ben_xiao_xiong_eat_num}"
          >
            <div class="name">笨笨小熊</div>
            <img src="../../assets/kingOfEating/image/xiaoxiong.png" alt="" />
            <div class="price">
              <img src="../../assets/kingOfEating/image/driedFish.png" alt="" />
              <span class="spanNum"> {{ ben_ben_xiao_xiong_eat_num }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 记录 -->
    <div class="recordsBox" v-if="showRecords">
      <div class="property-gift-box">
        <img
          src="../../assets/kingOfEating/image/return.png"
          alt=""
          class="property-gift-close"
          @click="showRecords = false"
        />
        <div class="content content-records">
          <div class="content-title">游戏记录</div>
          <div class="content-list">
            <div class="content-list-title">
              <div class="objectName">投注对象</div>
              <div class="bettingNum">投注数量</div>
              <div class="bettingTime">时间</div>
              <div class="bettingResult">中奖结果</div>
            </div>
            <div
              class="content-list-item"
              v-for="item in gameHistoryList"
              :key="item.id"
            >
              <div class="objectName">{{ item.rod_type == 'ke_ai_meng_tu' ?'可爱萌兔':item.rod_type == 'tan_chi_he_ma'?'贪吃河马':item.rod_type == 'yong_lan_xiao_mao'?
                '慵懒小猫':item.rod_type == 'ben_ben_xiao_xiong'?'笨笨小熊': item.rod_type == 'qin_lao_xiao_niao'?'勤劳小鸟':'聪明狐狸' }}</div>
              <div class="bettingNum">{{ item.buy_num }}</div>
              <div class="bettingTime">{{ item.created_at }}</div>
              <div class="bettingResult">{{ item.get_num }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 规则 -->
    <div class="rulesBox" v-if="showRule">
      <div class="property-gift-box">
        <img
          src="../../assets/kingOfEating/image/return.png"
          alt=""
          class="property-gift-close"
          @click="showRule = false"
        />
        <div class="content" v-html="dataObj.gold.remark"></div>
      </div>
    </div>

    <!-- 二次确认 -->
    <div class="confirm-purchase" v-if="confirmPurchase">
      <div class="confirm-purchase-content">
        <img
          src="../../assets/kingOfEating/image/okBuy.png"
          alt=""
          style="height: 2.4rem;margin-bottom: .3rem;"
        />
        <img
          src="../../assets/kingOfEating/image/driedFish.png"
          alt=""
          style="height: .5rem; width: .6rem;"
        />
        <div style="margin: .4rem 0 .3rem;">鱼干{{ buyInfo.get_fish_num }}</div>
        <div>
          <img
            src="../../assets/gold.png"
            alt=""
            style="width: .5rem;vertical-align: middle;"
          />
          {{ buyInfo.jin_bi_num }}
        </div>
        <div class="confirm-purchase-btn">
          <img
            src="../../assets/kingOfEating/image/yesBtn.png"
            alt=""
            @click="yesExchangeHandle"
          />
          <img
            src="../../assets/kingOfEating/image/cancelBtn.png"
            alt=""
            @click="confirmPurchase = false"
          />
        </div>
      </div>
    </div>

    <audio id="audio" ref="audio" autoplay>
      <source :src="videoUrl" type="audio/wav" />
    </audio>
    <!-- <div class="box"> -->

    <!-- <div class="btns">
          <div @click="batchPlay('one')" class="playBtn" :class="{ one: oneImg }">
            <span>1次</span>
          </div>
          <div
            @click="batchPlay('five')"
            class="playBtn"
            :class="{ five: fiveImg }"
          >
            <span>5次</span>
          </div>
          <div @click="batchPlay('ten')" class="playBtn" :class="{ ten: tenImg }">
            <span>10次</span>
          </div>
         
        </div> -->
    <!-- </div> -->

    <prize v-if="showPrize" :prize="prize" @close="reset" />
  </div>
</template>

<script>
import "@/assets/kingOfEating/css/index.css";
import rule from "@/components/rule";
import prize from "@/components/prize";
import { Toast } from "vant";

import activeImg from "../../assets/newegg/image/new-hammer.png";

import bgMusic from "../../assets/kingOfEating/video/bg.mp3";
import checkBg from "../../assets/kingOfEating/video/checkBg.mp3";
import prizeBg from "../../assets/kingOfEating/video/prizeBg.mp3";

export default {
  components: {
    prize,
    rule,
  },
  data() {
    return {
      showPropertyGift: false,
      showGivePrize: false,
      showEvents: false,
      showRecords: false,
      showDrawLottery: false,
      confirmPurchase: false, //确认购买
      fishNum: 0, //鱼干
      typeActive: 0,
      typeItem: 0,
      isBetting: 0,
      daoJuList: [],
      liWuList: [],
      MallArray: [],
      gamerecordList: [], // 游戏记录
      gameHistoryList: [], // 历史开奖
      drawLotteryActiveNum:0,
      isZhong: false,
      videoUrl: '',
      videoTime: "",
      gameId: 0,
      // rulesBox: false,
      countdown: 0 * 1000,
      showRule: false, //////////
      showPrize: false,
      gold: 0,
      prize: null,
      buyInfo: {},
      catchDom: "gold",
      // requestCompleted: true,
      num: "one",
      dataObj: {
        gold: {},
        silver: {},
        prizeClaw: {},
      },
      activeImg,
      playNum: "1",
      showEggGif: false,
      gameWinningRod: "", //中奖动物
      gameWinningImg: "", //中奖动物图片
      gameSpeed: 0, //倍速
      gameFishNum: 0, //鱼干
      gameIdBetting: 0, //下注游戏id
      gameBettingType: "ke_ai_meng_tu", //下注类型
      ben_ben_xiao_xiong_num: 0, //笨笨小熊
      cong_ming_hu_li_num: 0, //聪明狐狸
      ke_ai_meng_tu_num: 0, //可爱萌兔
      qin_lao_xiao_niao_num: 0, //勤劳小鸟
      tan_chi_he_ma_num: 0, //贪吃河马
      yong_lan_xiao_mao_num: 0, //慵懒小猫

      ben_ben_xiao_xiong_all_num: 0, //笨笨小熊
      cong_ming_hu_li_all_num: 0, //聪明狐狸
      ke_ai_meng_tu_all_num: 0, //可爱萌兔
      qin_lao_xiao_niao_all_num: 0, //勤劳小鸟
      tan_chi_he_ma_all_num: 0, //贪吃河马
      yong_lan_xiao_mao_all_num: 0, //慵懒小猫

      ben_ben_xiao_xiong_eat_num: 0,
      cong_ming_hu_li_eat_num: 0,
      ke_ai_meng_tu_eat_num: 0,
      qin_lao_xiao_niao_eat_num: 0,
      tan_chi_he_ma_eat_num: 0,
      yong_lan_xiao_mao_eat_num: 0,
    };
  },
  mounted() {
    this.getInfo();
    let audioDom = document.querySelector("#audio");
   
    audioDom.onended = function() {
      //播放完毕，重新循环播放
      audioDom.load();
      audioDom.play();
      this.videoTime = setTimeout(() => {
        this.$refs.audio && this.$refs.audio.play();
      }, 500);
    };
  },
  watch: {
    "globalData.XPLATFORM": {
      handler(newVal) {
        newVal && this.getInfo();
      },
    },
  },

  sockets: {
    // joinEatersGame      Betting
    disconnect() {
      console.log("Socket 断开");
    },
    error() {
      console.log("Socket 连接失败");
    },
    connect() {
      console.log("Socket 连接成功");
      this.$socket.emit("joinEatersGame", { room_no: this.globalData.roomId });
      // this.$socket.emit("Betting", { room_no: this.globalData.roomId });
    },
    gameRecordMassage(data) {
      console.log("gameRecordMassage", data);
      this.gamerecordList = data;
    },
    eatersGameAllMessages(data) {     //动物吃鱼干
      console.log("eatersGameAllMessages", data);
      this.showDrawLottery = true;
      setTimeout(()=>{
        let content = document.querySelectorAll('.spanNum');
        content.forEach(item => {
          item.style.transition ='opacity 2s ease-in-out';
          item.style.opacity = 1;
        })
      },500)
      this.ben_ben_xiao_xiong_eat_num = data.ben_ben_xiao_xiong
      this.cong_ming_hu_li_eat_num = data.cong_ming_hu_li
      this.ke_ai_meng_tu_eat_num = data.ke_ai_meng_tu
      this.qin_lao_xiao_niao_eat_num = data.qin_lao_xiao_niao
      this.tan_chi_he_ma_eat_num = data.tan_chi_he_ma
      this.yong_lan_xiao_mao_eat_num = data.yong_lan_xiao_mao

      let array = [data.ben_ben_xiao_xiong, data.cong_ming_hu_li, data.ke_ai_meng_tu, data.qin_lao_xiao_niao, data.tan_chi_he_ma, data.yong_lan_xiao_mao]

      setTimeout(() => {
        this.drawLotteryActiveNum = Math.max(...array)
      }, 2000)
      if(data.is_zhong == 0){
        this.isZhong = false
      } else {
        this.isZhong = true
      }
      setTimeout(() => {
        this.showDrawLottery = false;
        this.showGivePrize = true;
        
        if(data.is_zhong == 0){
          setTimeout(() => {
           this.showGivePrize = false;
           var content = document.querySelectorAll('.spanNum');
          content.forEach(item => {
          item.style.opacity = 0;
         })
           },3000)
          } else {
            // this.playMusic(prizeBg, 1);
            // setTimeout(() => {
            //   this.playMusic(bgMusic, 1);
            // },1000)
          }
      },4000)
    },
    receiveEatersMessage(data) {      //    中奖信息
      console.log("receiveEatersMessage",data);
      this.gameSpeed = data.beishu;
      this.gameFishNum = data.get_fish_gan_num;
      this.gameWinningRod = data.game.cat_name;
      this.gameWinningImg = data.game.show_img;

      setTimeout(() => {
        this.showGivePrize = false;
        this.isZhong = false;
        var content = document.querySelectorAll('.spanNum');
        content.forEach(item => {
        item.style.opacity = 0;
      })
      },6000)
    },
    eatersgameUser(data) {     // 用户数据
      console.log("eatersgameUser",data);
      this.fishNum = data.fish_gan_num;
      this.gold = data.gold;
    },
    eatersGameMassage(data) {
      console.log("eatersgameMassage", data);
      this.gameIdBetting = data.game_id;
      if (data.game_status == 1) {
        this.showEvents = true;
        this.countdown = data.game_time_left * 1000;

        this.ben_ben_xiao_xiong_all_num = data.ben_ben_xiao_xiong_all_tou;
        this.cong_ming_hu_li_all_num = data.cong_ming_hu_li_all_tou;
        this.ke_ai_meng_tu_all_num = data.ke_ai_meng_tu_all_tou;
        this.qin_lao_xiao_niao_all_num = data.qin_lao_xiao_niao_all_tou;
        this.tan_chi_he_ma_all_num = data.tan_chi_he_ma_all_tou;
        this.yong_lan_xiao_mao_all_num = data.yong_lan_xiao_mao_all_tou;

        this.ben_ben_xiao_xiong_num = data.ben_ben_xiao_xiong_user_tou;
        this.cong_ming_hu_li_num = data.cong_ming_hu_li_user_tou;
        this.ke_ai_meng_tu_num = data.ke_ai_meng_tu_user_tou;
        this.qin_lao_xiao_niao_num = data.qin_lao_xiao_niao_user_tou;
        this.tan_chi_he_ma_num = data.tan_chi_he_ma_user_tou;
        this.yong_lan_xiao_mao_num = data.yong_lan_xiao_mao_user_tou;
      } else {
        this.showEvents = false;
        this.countdown = data.game_time_xiu * 1000;
      }
      if (this.countdown == 0 && data.game_status == 1) {
        this.showDrawLottery = true;
      }
    },
  },

  methods: {

    // 切换选中
    checkHandle(num, name){
      this.isBetting = num
      this.gameBettingType = name
      this.playMusic(checkBg, 0.7);
      setTimeout(()=>{
        this.$refs.audio.pause();
      },800)
    },

    // 音效
    playMusic(musicType, speed) {
      this.videoUrl = musicType;
      this.$refs.audio.load(); // 加载音频文件
      this.$refs.audio.play(); // 播放音频文件
      this.$refs.audio.playbackRate = speed; // 播放音频文件的倍速
    },

    // 礼物
    eventGiftHandle() {
      this.showPropertyGift = true;
      this.typeActive = 1;
      this.MallArray = this.liWuList;
    },
    // 道具
    propertyGiftHandle() {
      this.showPropertyGift = true;
      this.typeActive = 0;
      this.MallArray = this.daoJuList;
    },
    // 切换
    typeActivehandle(type) {
      if (type == 0) {
        this.MallArray = this.daoJuList;
        this.typeActive = type;
      } else {
        this.MallArray = this.liWuList;
        this.typeActive = type;
      }
    },
    getInfo() {
      if (this.globalData.XPLATFORM) {
        this.$http
          .get("lottery-game/info", {
            params: { code: "ke_ai_meng_tu" },
            headers: {
              "X-PLATFORM": this.globalData.XPLATFORM,
            },
          })
          .then((res) => {
            this.dataObj.prizeClaw = res.data;
            this.gold = res.data["gold"];
            this.dataObj.gold.remark = res.data.remark;
            this.daoJuList = res.data.dao_ju_column;
            this.gameId = res.data.id;
            this.liWuList = res.data.dww_active_prize.map((v) => {
              return {
                jin_bi_num: v.amount,
                get_fish_num: v.prize.name,
                title: "100",
                image: v.prize.image,
                id: v.gift_id,
              };
            });
          });
      }
    },
    reset() {
      this.$refs.audio.pause();
      // $("#" + this.catchDom).attr("src", this.relevanceObj[this.catchDom]);
      // this.showPrize = false;
      // this.isShowImg = true;
      // this.requestCompleted = true;
      // this.getInfo();
    },
    // play() {
    //   //   this.isShowImg = false;
    //   const limit = this.num == "one" ? "price" : this.num + "_price";

    //   if (parseInt(this.gold) < parseInt(this.dataObj.prizeClaw[limit])) {
    //     return Toast.fail({ message: "余额不足", position: "bottom" });
    //   }
    //   // this.catchDom = dom;
    //   // $('#' + dom).attr('src', this.gifObj[dom]);
    //   this.getData(this.num, "toy_crane");
    // },
    // batchPlay(type) {
    //   if (type == "one") {
    //     this.num = "one";
    //     this.oneImg = true;
    //     this.fiveImg = false;
    //     this.tenImg = false;
    //     this.playNum = "1";
    //     this.play();
    //   } else if (type == "five") {
    //     this.num = "five";
    //     this.oneImg = false;
    //     this.fiveImg = true;
    //     this.tenImg = false;
    //     this.playNum = "5";
    //     this.play();
    //   } else if (type == "ten") {
    //     this.num = "ten";
    //     this.oneImg = false;
    //     this.fiveImg = false;
    //     this.tenImg = true;
    //     this.playNum = "10";
    //     this.play();
    //   }
    // },
    // getData(type, dom) {
    //   if (!this.globalData.XPLATFORM) {
    //     return;
    //   }
    //   if (!this.requestCompleted) {
    //     return;
    //   }
    //   this.requestCompleted = false;
    //   const that = this;
    //   this.$refs.audio.load();
    //   this.$refs.audio.play();
    //   this.$refs.audio.playbackRate = 0.5;
    //   this.$http
    //     .post(
    //       "lottery-game/draw",
    //       {
    //         type,
    //         code: dom,
    //         room_id: that.globalData.roomId,
    //       },
    //       {
    //         headers: { "X-PLATFORM": this.globalData.XPLATFORM },
    //       }
    //     )
    //     .then((res) => {
    //       this.prize = res;
    //       setTimeout(() => {
    //         that.showEggGif = false;
    //         that.showPrize = true;
    //         this.$refs.audio.playbackRate = 1;
    //       }, 800);
    //     })
    //     .catch((e) => {
    //       this.reset();
    //     });
    // },
    // 获取抽奖记录
    getHistoryHandle() {
      this.showRecords = true;
      this.$http
        .get("/eaters-game/get-record", {
          headers: {
            "X-PLATFORM": this.globalData.XPLATFORM,
          },
        })
        .then((res) => {
          console.log(res, "抽奖记录");
          this.gameHistoryList = res.data;
        });
    },
    // 兑换活动礼物和道具
    exchangeGiftHandle(item) {
      const that = this;
      if (this.typeActive == 0) {
        this.buyInfo = item;
        this.confirmPurchase = true;
      } else {
        this.$http
          .post(
            "/eaters-game/get-active-gift",
            {
              game_id: that.gameId,
              gifts_id: item.id,
              price: item.jin_bi_num,
              room_no: that.globalData.roomId,
            },
            {
              headers: { "X-PLATFORM": this.globalData.XPLATFORM },
            }
          )
          .then((res) => {
            // console.log(res, "兑换活动礼物");
            Toast({
              message: res.message,
              position: "bottom",
            });
          })
          .catch((e) => {
            // console.log(e, "兑eee换活动礼物");
            Toast({
              message: e.message,
              position: "bottom",
            });
          });
      }
    },

    // 确定购买
    yesExchangeHandle() {
      const that = this;
      this.$http
        .post(
          "/eaters-game/get-food",
          {
            game_id: that.gameId,
            fish_num: that.buyInfo.jin_bi_num,
            room_no: that.globalData.roomId,
          },
          {
            headers: { "X-PLATFORM": this.globalData.XPLATFORM },
          }
        )
        .then((res) => {
          Toast({
            message: res.message,
            position: "bottom",
          });
          this.confirmPurchase = false;
        })
        .catch((e) => {
          // console.log(e, "道具eee");
          Toast({
            message: e.message,
            position: "bottom",
          });
        });
    },

    // 下注
    bettingBtn(type) {
      this.playNum = type;
      if (parseInt(this.fishNum) < parseInt(this.playNum)) {
        return Toast.fail({ message: "鱼干不足,请兑换", position: "bottom" });
      } else {
        this.$socket.emit("Betting", {
          game_id: this.gameIdBetting,
          rod_type: this.gameBettingType,
          room_no: this.globalData.roomId,
          buy_num: this.playNum,
        });
      }
    },

    // 购买鱼干
    addFishNumHandle(){
      this.showPropertyGift = true
      this.typeActive = 0
      this.MallArray = this.daoJuList;
    }
  },

  beforeDestroy() {
    this.$socket.disconnect();
    clearTimeout(this.videoTime);
    this.$refs.audio.pause(); // 停止播放
  },
};
</script>

<style scoped></style>
